import React, { useState, useRef, useEffect } from "react"
import { useLocation } from '@reach/router'
import { navigate } from "gatsby"

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"

import PAYMENT_API from "../api/payment"
import EVENTS_API from "../api/events"

import useScript from '../service/useScript'
import Loader from "../components/addon/Loader"

import { GATSBY_BILLDESK_URL } from '../service/config'

const Payment = () => {

    const location = useLocation()
    const metaTags = [];
    const [msg, setMsg] = useState(null)
    const [callbackUrl, setCallbackUrl] = useState(null)
    //useScript("https://pgi.billdesk.com/payments-checkout-widget/src/app.bundle.js");
    //useScript("https://uat.billdesk.com/pgidsk/PGIMerchantPayment");
    //useScript(GATSBY_BILLDESK_URL)
    const [newRes, setNewRes] = useState(null)
    const refClick = useRef(null);

    const payChecksum = () => {
        let payload = { msg: msg }
        PAYMENT_API.paymentChecksum(payload)
        .then(res => {
            setNewRes(res)
            triggerSubmit(res)
            //paymentInitialize(res)
        })
        .catch(err => {
            console.log(err)
        })
    }

    const eventPaymentCheckSum = () => {
        let payload = { msg: msg }
        EVENTS_API.eventChecksum(payload)
        .then(res => {
            setNewRes(res)
            triggerSubmit(res)
            //paymentInitialize(res)
        })
        .catch(err => {
            console.log(err)
        })
    }

    const paymentInitialize = (res) => {
        // setTimeout(() => {
        //     window?.bdPayment?.initialize({
        //         msg: `${msg}|${res}`,
        //         options: {
        //             "enableChildWindowPosting": false,
        //             "enablePaymentRetry": true,
        //             "retry_attempt_count": 2,
        //             // "txtPayCategory": "NETBANKING"
        //         },
        //         callbackUrl: callbackUrl
        //     });
        // }, 500)
        //setNewRes(res)
        //res.length &&  setTimeout(() => { refClick.current.click()}, 100);
        
        //{ console.log('check------------- ',`${msg}|${newRes}`)}
        //PAYMENT_API.abcd(`${msg}|${res}`)
        //PAYMENT_API.abcd(`msg: UATHINDUV1|HHONLDEPPAY-DEP-100448|NA|1.00|NA|NA|NA|INR|NA|R|uathinduv1|NA|NA|F|1234|TESTFIRST PRASHANT TESTLAST|31-Mar-2023 10 25 PM|NA|KO6|OPD|NA|https://admin.hindujahospital.com/api/billdesk/callBackUrl|355106759DEBADB7EC3E99A0428EC9E46AE9F22529F2A437AD21FFC451C51AF4`)
    }
    const triggerSubmit = (res) => {
        setTimeout(() => { 
            refClick.current.click()
        }, 100);
    }
    useEffect(() => {
        if(msg?.length) {
            if(location.state?.events) {
                eventPaymentCheckSum()
            } else {
                payChecksum()
            }
        }
    },[msg])
    
    useEffect(() => {
        if(location && window && location?.state !== null) {
            if(location.state?.msg) {
                setMsg(location.state?.msg)
            }
            if(location.state?.callback) setCallbackUrl(location.state?.callback)
            if(!location.state?.msg || !location.state?.callback) {
                navigate('/')
            }
        } else {
            navigate('/')
        }
    },[location])
    
    return (
        <Layout>
            <Meta
                title="payment"
                files={{
                    js: [],
                    css: ["/assets/css/login.css" ,"/assets/css/accessibility.css"]
                }}
                tags={metaTags}
            />
            <main className="innerpage">
                { console.log('dddddd====',`${msg}|${newRes}`)}
                <form method='POST' action='https://pgi.billdesk.com/pgidsk/PGIMerchantPayment'>
                    <input type='hidden' name='msg' value={`${msg}|${newRes}`} />
                    <input className="invisible" type='Submit' ref={refClick} />
                </form>
            </main>
            <section className="section_bg login_section pt-first-section">
                <Loader loadingMessage={`Please wait...`} />
            </section>
        </Layout>
    )
}

export default Payment